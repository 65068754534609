import React from 'react';
import videoConnect from 'react-html5video';

const VideoComponent = ({video, videoEl, source = [], muted, autoPlay, loop, ...restProps}) => (
    <video playsinline
           muted={muted}
           autoPlay={autoPlay}
           loop={loop}
           {...restProps}
    >
        {source.map((el, i) => {
            return <source key={i} src={el} type={`video/${el.split('.').pop()}`} />
        })}
    </video>
);

export default videoConnect(VideoComponent);
